<template>
  <div class="block-first about-us">
    <div class="section section__first">
      <div class="section-text section-text--left">
        <h3 class="section-title">Uđi, ne moraš se izuvat’</h3>
        <p>
          Želiš konačno naučiti novi jezik, ali da ti to iskustvo bude ugodno,
          zabavno i uštedi vrijeme? Nemoj stajati na vratima!
        </p>
      </div>
      <div class="section-image">
        <div class="door-closed">
          <img src="@/assets/door.webp" alt="Online škola" />
        </div>
        <div class="door-open">
          <img src="@/assets/door2.webp" alt="Škola stranih jezika" />
        </div>
      </div>
    </div>

    <div class="section section__second">
      <div class="section-image">
        <img
          src="@/assets/computer.webp"
          alt="Online tečaj njemačkog"
          loading="lazy"
        />
      </div>
      <div class="section-text">
        <h3 class="section-title">Naši profesori</h3>
        <p>
          Studirali su njemački i engleski, ali i živjeli u Njemačkoj, Austriji
          i SAD-u pa te osim nepravilnih glagola mogu naučiti i kako ispeći
          Wiener Schnitzel ili američke palačinke.
        </p>
        <br />
        <p>
          Pravila su nam važna, ali i to da se oslobodiš u pričanju stranog
          jezika. Dodatnih materijala ti sigurno neće nedostajati!
        </p>
      </div>
    </div>

    <div class="section section__third">
      <div class="section-text section-text--left">
        <h3 class="section-title">Kako je nastala Spika?</h3>
        <p>
          Mi smo Marko i Ana - dvoje prijatelja koji obožavaju strane jezike,
          ali ne vole “školski” način učenja i tradicionalno “štrebanje”.
        </p>
        <br />
        <p>
          Spika je nastala kao naša želja da uklonimo svakodnevne prepreke koje
          te sprječavaju u učenju, poput odlaska u školu ili nedostatka vremena.
        </p>
        <br />
        <p>
          Učenje ne mora biti teško i dosadno... Može biti ugodno poput kave s
          frendom. Zato nam se javi i spikaj s nama!
        </p>
      </div>
      <div class="section-image section-image--pair">
        <img
          src="@/assets/ekran.webp"
          alt="Profesor engleskog jezika"
          loading="lazy"
        />
      </div>
    </div>

    <div class="section section__fourth">
      <div class="section-image section-image--thumb">
        <img
          src="@/assets/thumbsup.webp"
          alt="Učenje stranog jezika online"
          loading="lazy"
        />
      </div>
      <div class="section-text">
        <h3 class="section-title">
          Kao da je itko ikada došao do kraja stranice...
        </h3>
        <p>
          Ako ovako detaljno pratiš upute na satu kao što čitaš sadržaj naše web
          stranice, vjerujemo da ćeš brzo naučiti svaki jezik!
        </p>
        <br />
        <p>
          Filmove pratimo bez titlova, a u školi su nas uvijek žicali za zadaću
          iz njemačkog. Vjeruješ li nam napokon da te možemo naučiti strani
          jezik?
        </p>
        <router-link to="/prijava" class="primary-button"
          >Prijavi se</router-link
        >
      </div>
    </div>
  </div>
</template>
